import React from 'react'
import { graphql, navigate } from 'gatsby'
import Layout from '../components/Layout'
import { isLoggedIn } from '../utils/auth'

export default function Page({ data, location }) {
  if (!data.markdownRemark.frontmatter) {
    return null
  }

  const { frontmatter, html } = data.markdownRemark

  if (
    !isLoggedIn() &&
    location.pathname !== `/login` &&
    typeof window !== 'undefined'
  ) {
    // If the user is not logged in, redirect to the login page.
    navigate(`/login`)
    return null
  }

  return (
    <Layout currentPath={location.pathname} frontmatter={frontmatter}>
      <div
        className="c-content__markdown"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
        layout
      }
    }
  }
`
